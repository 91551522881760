<template>
  <div  class="px-15px px-lg-25px">

    <section class="gry-bg py-4 profile">
      <div class="container-fluid">
        <form class="">
          <div class="row gutters-10">
            <div class="col-md">
              <div class="row gutters-5 mb-3">
                <div class="col-md-2 mb-2 mb-md-0">
                  <div class="form-group mb-0">
                    <input class="form-control " type="text" @blur="searchGood" v-model="form.title" :placeholder="$t('qing-shu-ru-chan-pin-ming-cheng')" >
                  </div>
                </div>
                <div class="col-md-2 mb-2 mb-md-0">
                  <div class="form-group mb-0">
                    <input class="form-control " type="text" @blur="searchGood" v-model="form.MinPrice" :placeholder="$t('qing-shu-ru-zui-di-jia-ge')" >
                  </div>
                </div>
                <div class="col-md-2 mb-2 mb-md-0">
                  <div class="form-group mb-0">
                    <input class="form-control " type="text" @blur="searchGood" v-model="form.MaxPrice" :placeholder="$t('qing-shu-ru-zui-gao-jia-ge')" >
                  </div>
                </div>
                <div class="col-md-6 mb-md-0  mb-2">
                  <a-select  class="form-control clear" size="large" v-model="form.category" @change="changeCategory" show-search :filter-option="filterOption">
                    <a-select-option value="">{{ $t('suo-you-fen-lei') }}</a-select-option>
                    <a-select-option :value="item.ID" v-for="(item, i) in categoryList" :key="i">{{item.Name}}</a-select-option>
                  </a-select>
                </div>
                <!-- <div class="col-md-3 col-6">
                  <a-select class="form-control clear" size="large" v-model="form.company">
                    <a-select-option value="">{{ $t('suo-you-mai-jia') }}</a-select-option>
                    <a-select-option :value="item.value" v-for="(item, i) in sellerList" :key="i">{{item.label}}</a-select-option>
                  </a-select>
                </div> -->
              </div>

               <div class=" mt-3 mb-3 show-xs">
                <button id="add-all-btn" type="button" class="btn btn-outline-info btn-block" @click="addAll">
                  <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
                  {{ $t('quan-bu-tian-jia-dao-wo-de-chan-pin') }} </button>
              </div>

              <div class=" mt-3 mb-3 show-xs">
                <button id="add-all-btn" type="button" class="btn btn-outline-info btn-block" @click="selectAll">
                  <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
                  {{ $t('quan-xuan') }} </button>
              </div>



              <div class="aiz-pos-product-list c-scrollbar-light">
                <div class="d-flex flex-wrap justify-content-center" id="product-list">
                  <div class="w-140px w-xl-180px w-xxl-210px mx-2" v-for="(item, i) in productList" :key="i">
                    <div class="card bg-white c-pointer product-card hov-container">
                      <div class="position-relative">
                        <span class="absolute-top-left mt-1 ml-1 mr-0">
                          <span class="badge badge-inline badge-success fs-13">{{ $t('you-cun-huo') }} {{item.Stock}}</span>
                        </span>
                        <!-- <span class="badge badge-inline badge-warning absolute-bottom-left mb-1 ml-1 mr-0 fs-13 text-truncate"></span> -->
                        <img :src="item.MainImage" class="card-img-top img-fit h-120px h-xl-180px h-xxl-210px mw-100 mx-auto">
                      </div>
                      <div class="card-body p-2 p-xl-3">
                        <div class="text-truncate fw-600 fs-14 mb-2">{{item.ProductTranslation && item.ProductTranslation[0] ? item.ProductTranslation[0].Name : ''}}</div>
                        <div class="">
                          <span>{{resetPrice(item.MarketPrice)}}</span>
                        </div>
                      </div>
                      <div class="add-plus absolute-full rounded overflow-hidden hov-box " @click="addGoods(item)">
                        <div class="absolute-full bg-dark opacity-50"></div>
                        <i class="las la-plus absolute-center la-6x text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div id="load-more" class="text-center" v-if="!isFinshed">
                  <div class="fs-14 d-inline-block fw-600 btn btn-soft-primary c-pointer"  @click="nextPage">{{loading ? $t('loading')+'...' : $t('loadingMore')}}</div>
                </div> -->
              </div>
              <div class="aiz-pagination" v-if="page.total > 1">
                <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
              </div>
            </div>
            <div class="col-md-auto w-md-350px w-lg-400px w-xl-500px">
              <div class="card mb-3">
                <div class="card-body">
                  <div class="">
                    <div class="aiz-pos-cart-list mb-4 mt-3 c-scrollbar-light">
                      <ul class="list-group list-group-flush" id="product-selection">
                        <li class="list-group-item py-3 pl-2" v-for="(item, i) in goodsList" :key="i">
                          <div class="row gutters-5 align-items-center">
                            <div class="col">
                              <div class="text-truncate-2">{{item.ProductTranslation && item.ProductTranslation[0] ? item.ProductTranslation[0].Name : ''}}</div>
                              <span class="span badge badge-inline fs-12 badge-soft-secondary"></span>
                            </div>
                            <div class="col-auto">
                              <div class="fs-15 fw-600">{{resetPrice(item.MarketPrice)}}</div>
                            </div>
                            <div class="col-auto">
                              <button type="button" class="btn btn-circle btn-icon btn-sm btn-soft-danger ml-2 mr-0" @click="removeGoods(i)">
                                <i class="las la-trash-alt"></i>
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pos-footer mar-btm row ">
                <div class="col-lg-5 col-md-12 mb-3">
                  <div class="d-flex hide-xs ">
                    <button id="add-all-btn" type="button" class="btn btn-outline-info btn-block" @click="addAll">
                      <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
                      {{ $t('quan-bu-tian-jia-dao-wo-de-chan-pin') }} </button>
                  </div>
                </div>
                <div class="col-lg-7 col-md-12 mb-3">
                  <div class="d-flex flex-column flex-md-row justify-content-between">
                    <div class="d-flex hide-xs">
                      <button id="add-all-btn" type="button" class="btn btn-outline-info btn-block" @click="selectAll">
                        <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
                        {{ $t('quan-xuan') }} </button>
                    </div>

                    <div class="my-2 my-md-0">
                      <button id="add-selection-btn" type="button" class="btn btn-primary btn-block"  @click="addSelect">
                        <span class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
                        {{ $t('tian-jia-dao-wo-de-chan-pin') }} </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import pageBar from '@/components/page'
import { Popover  } from 'vant'
import {  getCategoryList } from '@/api/index'
import { addProduct, getGoodsList } from '@/api/seller'
import { resetPrice } from '@/utils/common'
export default {
  components: {
    pageBar,
    Popover
  },
  data() {
    return {
      form: {
        category: '',
        company: '',
        MinPrice: '',
        MaxPrice: '',
        title: ''
      },
      hasPackage: true,
      showPopover: false,
      packageUrl: require('../../../assets/imgs/package.png'),
      productList: [],
      tableData: [],
      goodsList: [],
      loading: true,
      page: {
        page: 1,
        limit: 10,
        total: 1
      },
      sellerList: [],
      categoryList: [],
      isFinshed: false
    }
  },
  computed: {
  },
  mounted() {
    this.initCategory()
    this.init()
  },
  methods: {
    resetPrice,
    initCategory() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      form.append('ParentID', 0)
      getCategoryList(form).then(res => {
        this.categoryList = res.data.Items.map(v => {
          let data = v.ProductCategoryTranslations[0]
          return {
            ...v,
            Name:  data ? data.Name : '',
          }
        })
      })
    },
    searchGood() {
      this.page.page = 1
      this.init()
    },
    init() {
      this.isFinshed = false
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('title', this.form.title)
      // 去除重复
      form.append('CategoryID', this.form.category)
      if(this.form.MinPrice) {
        form.append('MinPrice', this.form.MinPrice)
      }
      if(this.form.MaxPrice) {
        form.append('MaxPrice', this.form.MaxPrice)
      }
      form.append('Distinct', 1)
      form.append('OrderBy', 1)
      this.loading = true
      getGoodsList(form).then(res => {
        this.loading = false
        this.productList = res.data.Items
        this.page.total = res.data.Pagination ? res.data.Pagination.totalPage : 1
      }).catch(e => {
         this.loading = false
      })
    },
    nextPage() {
      if (this.loading) {
        return
      }
      this.page.page += 1
      this.init(true)
    },
    removeGoods(i) {
      this.goodsList.splice(i, 1)
    },
    addGoods(data) {
      let index = this.goodsList.findIndex(v => {
        return v.ID == data.ID
      })
      if (index === -1) {
        this.goodsList.push(data)
      }
    },
    selectAll() {
      this.goodsList = [].concat(this.productList)
    },
    addAll() {
      let ids = this.productList.map(v => {
        return v.ID
      })
      this.doAdd(ids)
    },
    addSelect() {
      let ids = this.goodsList.map(v => {
        return v.ID
      })
      this.doAdd(ids)
    },
    doAdd(ids) {
      addProduct({
        ProductID: ids
      }).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('tian-jia-cheng-gong'))
          this.goodsList = []
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    changeCategory() {
      this.page.page = 1
      this.init()
    },
    changePage(page) {
      this.page.page = page
      this.init()
    },
  }
}
</script>